/* eslint react/no-array-index-key: 0 */
import React from "react"
import { connect, FieldArray } from "formik"

import * as C from "shared/components"
import { reachOptions } from "components/persona/constants"

import { BrandsTextarea } from "./BrandsTextarea"

const reachSliders = reachOptions.filter(r => r.name !== "brands")

export const ReachSection = connect(({ formik }) => {
  const { reach } = formik.values
  const options = reach.filter(r => r.name !== "brands")
  const brandsOptionIndex = reach.findIndex(r => r.name === "brands")

  const addOptions = reachSliders.filter(
    slider => !options.find(option => option.id === slider.id)
  )

  return (
    <FieldArray
      name="reach"
      render={arrayHelpers => {
        const addAction = id => {
          const item = reachSliders.find(option => option.id === id)

          if (item) {
            arrayHelpers.push(item)
          }
        }

        return (
          <C.Card
            width="20rem"
            title="Reach"
            maxWidth="32%"
            dotColor="pink"
            addAction={options.length < 6 ? addAction : undefined}
            addOptions={addOptions}
          >
            {brandsOptionIndex === 0 && (
              <BrandsTextarea name="reach[0].value" />
            )}
            {options.map((r, index) => (
              <C.Slider
                key={r.id}
                name={`reach[${index + 1}]`}
                title={r.label}
                onRemove={() => {
                  arrayHelpers.remove(index + 1)
                  formik.submitForm()
                }}
              />
            ))}
          </C.Card>
        )
      }}
    />
  )
})
