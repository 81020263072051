/* eslint react/no-array-index-key: 0 */
import React from "react"
import { connect, FieldArray } from "formik"

import * as C from "shared/components"
import * as O from "components/persona/constants"

export const MotivationToBuySection = connect(({ formik }) => {
  const { motivations } = formik.values
  const motivationsOptions = O.motivationsOptions.filter(
    co => !motivations.find(c => c.id === co.id)
  )

  return (
    <FieldArray
      name="motivations"
      render={arrayHelpers => {
        const addAction = id => {
          const item = motivationsOptions.find(mo => mo.id === id)
          const duplicate = motivations.find(d => d.id === id)

          if (item && !duplicate) {
            arrayHelpers.push(item)
          }
        }

        return (
          <C.Card
            width="20rem"
            maxWidth="32%"
            title="Motivation to buy"
            dotColor="lightGreen"
            addOptions={motivationsOptions}
            addAction={motivations.length < 5 ? addAction : undefined}
          >
            {motivations.map((r, index) => (
              <C.Slider
                key={r.id}
                name={`motivations[${index}]`}
                title={r.label}
                onRemove={() => {
                  arrayHelpers.remove(index)
                  formik.submitForm()
                }}
              />
            ))}
          </C.Card>
        )
      }}
    />
  )
})
