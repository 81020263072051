export const dropdownOptions = {
  sex: [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
  ],
  companySize: [
    { value: "1-2", label: "1-2" },
    { value: "3-10", label: "3-10" },
    { value: "<50", label: "<50" },
    { value: "<250", label: "<250" },
    { value: "<1000", label: "<1000" },
    { value: ">1000", label: ">1000" },
  ],
  education: [
    { value: "Pre-academic", label: "Pre-academic" },
    { value: "Bachelor", label: "Bachelor" },
    { value: "Master", label: "Master" },
    { value: "Doctoral", label: "Doctoral" },
    { value: "Other", label: "Other" },
  ],
  familyStatus: [
    { value: "Single", label: "Single" },
    { value: "Married no kids", label: "Married no kids" },
    { value: "Married young kids", label: "Married young kids" },
    { value: "Married old kids", label: "Married old kids" },
    { value: "Senior", label: "Senior" },
    { value: "Other", label: "Other" },
  ],
}
