/* eslint react/no-array-index-key: 0 */
import React from "react"
import { connect, FieldArray } from "formik"

import * as C from "shared/components"

export const SkillsSection = connect(({ formik }) => {
  const { skills } = formik.values

  return (
    <FieldArray
      name="skills"
      render={arrayHelpers => {
        const addSkillAction = e => {
          e.preventDefault()
          arrayHelpers.push({ key: Math.random(), name: "", value: 125 })
        }

        return (
          <C.Card
            width="20rem"
            height="26.8rem"
            maxWidth="32%"
            title="Skills"
            dotColor="yellow"
            addAction={skills.length < 6 ? addSkillAction : undefined}
          >
            {skills.map((skill, index) => (
              <C.Slider
                key={skill.key}
                name={`skills[${index}]`}
                placeholder="Provide skill name"
                onRemove={() => {
                  arrayHelpers.remove(index)
                  formik.submitForm()
                }}
              />
            ))}
          </C.Card>
        )
      }}
    />
  )
})
