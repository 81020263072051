import React, { useRef, useState, useEffect } from "react"
import { Formik, Form } from "formik"

import * as L from "layouts"
import * as PC from "components/persona"
import * as O from "components/persona/constants"
import * as U from "components/persona/utils"
import Axios from "axios"

const Persona = () => {
  const [downloadError, setDownloadError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [initialValues, setInitialValues] = useState(null)
  const [isPhotoUploaded, setIsPhotoUploaded] = useState(false)

  const printContainer = useRef(null)

  const persona = U.usePersonaState()

  useEffect(() => {
    const localPersona = JSON.parse(
      window.localStorage.getItem(O.localStorageKey)
    )
    if (localPersona) {
      setInitialValues(localPersona)
      return
    }

    setInitialValues(O.initialValues)
  }, [])

  const savePersona = values => {
    persona.savePersonaNoModal(values)
    window.localStorage.setItem(O.localStorageKey, JSON.stringify(values))
  }

  const resetInitialValues = () => {
    setIsPhotoUploaded(false)
    setInitialValues(null)
  }

  const submitPersonaForm = (values, actions) => {
    actions.setSubmitting(false)
    savePersona(values)
  }

  const toast = () => {
    setDownloadError(true)
    setTimeout(() => {
      setDownloadError(false)
    }, 3000)
  }

  const closeModal = () => {
    setSuccess(false)
    persona.closeModal()
  }

  const submitDownloadModal = async (values, { setSubmitting }) => {
    setLoading(true)
    const result = await U.generateAndSendPdf(
      persona.personaDetails,
      values,
      printContainer.current
    )
    setLoading(false)
    setSubmitting(false)

    // This needs to be formdata, because backend doesn't support JSON body
    const formData = new FormData()
    formData.append("email", values.email)
    formData.append("full_name", values.fullName)
    formData.append("company_name", values.company)
    formData.append("role", values.role)
    formData.append("terms", "false")
    formData.append("lead_source", "Persona")
    // See: https://github.com/startupdevhouse/startup-house/blob/production/app/controllers/api/base_controller.rb#L16
    formData.append("validate_recaptcha", "false")

    try {
      await Axios.post(O.LEADS_SDH_URL, formData)
    } catch (e) {
      // eslint-disable-next-line
      console.error(e)
    }

    if (values.isTermsAccepted) {
      try {
        await Axios.post(O.NEWSLETTER_SUBSCRIPTION_URL, {
          email: values.email,
          source: "Persona",
        })
      } catch (e) {
        // eslint-disable-next-line
        console.error(e)
      }
    }

    if (result && result.success) {
      setSuccess(true)
    } else {
      toast() // TODO: Here should be error handling. Improve this when desing will be ready.
      // eslint-disable-next-line
      console.error(result.error)
    }
  }

  return (
    <L.PersonaLayout>
      <Formik
        initialValues={initialValues || O.initialValues}
        onSubmit={submitPersonaForm}
        enableReinitialize
      >
        <Form>
          <PC.Header resetInitialValues={resetInitialValues} />
          <PC.PersonaForm
            photo={initialValues ? initialValues.photo : ""}
            openModal={persona.openModal}
            savePersona={savePersona}
            isPhotoUploaded={isPhotoUploaded}
            setIsPhotoUploaded={setIsPhotoUploaded}
          />
        </Form>
      </Formik>
      <PC.PrintView {...persona.personaDetails} ref={printContainer} />
      <PC.DownloadModal
        success={success}
        loading={loading}
        isOpen={persona.isModalOpen}
        downloadError={downloadError}
        onSubmit={submitDownloadModal}
        onRequestClose={closeModal}
      />
    </L.PersonaLayout>
  )
}

export default Persona
