import React from "react"
import { connect } from "formik"

import * as C from "shared/components"

import * as TSC from "./components"
import * as S from "./styled"

export const TopSection = connect(({ formik, openModal, savePersona }) => {
  const validatePersona = e => {
    e.preventDefault()
    savePersona(formik.values)

    if (!formik.values.title) {
      formik.setFieldError("title", "Required")
      return
    }
    openModal()
  }

  return (
    <S.TopSectionWrapper>
      <TSC.TitleInput />
      <C.Button
        size="xl"
        elevation={1}
        elevationStep={1}
        type="submit"
        overrides={{ minWidth: "10.625rem" }}
        onClick={validatePersona}
      >
        Download PDF
        <S.IconDownload />
      </C.Button>
    </S.TopSectionWrapper>
  )
})
